import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import VendorLeftSection from './VendorLeftSection';
import vendorContext from '../context/vendor/vendorContext';

const VendorDashboard = ({ children }) => {
  const { vendorName } = useContext(vendorContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to toggle sidebar visibility on mobile

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex min-h-screen border-l-4 border-[#FB8802]">
      {/* Hamburger Button for Mobile */}
      <button
        className="lg:hidden fixed top-4 left-4 z-20 bg-[#FB8802] text-white rounded-full w-10 h-10 flex items-center justify-center shadow-md"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? '✖' : '☰'} {/* Toggle between Cross (✖) and Hamburger (☰) */}
      </button>

      {/* Sidebar Section */}
      <div
        className={`fixed lg:static top-0 left-0 h-full w-64 bg-white shadow-lg z-10 transform transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
      >
        <VendorLeftSection handleOptionClick={() => {}} />
      </div>

      {/* Right Section */}
      <div className="flex-grow lg:w-5/6 flex flex-col">
        {/* Header */}
        <div className="xh-14 bg-gradient-to-r from-[#FB8802] to-[#FFE649] flex justify-between items-center pr-4 text-4xl text-black shadow-md">
          <h1 style={{marginLeft:"24px"}} className="text-xl font-semibold w-full text-center lg:text-left">
            Vendor Dashboard
          </h1>
          <div className="hidden lg:block text-lg font-semibold text-black pr-4">
            {vendorName}
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-grow p-4 bg-white rounded-lg shadow-md">
          <Routes>{children}</Routes>
        </div>
      </div>
    </div>
  );
};

export default VendorDashboard;
