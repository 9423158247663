import React from 'react';
import '../App.css';

const SubmissionConfirmation = () => {
    return (
        <div className="confirmation-container flex flex-col items-start justify-center min-h-screen relative px-8">
            <div className="absolute" style={{ top: '20px', left: '20px' }}> {/* Position the logo */}
                <img 
                    src="/logo.png" // Using direct path from public folder
                    alt="Logo" 
                    className="logo-image" 
                    style={{ width: '220px', height: 'auto' }} // Increased size for the logo
                />
            </div>
            <h1 className="text-5xl font-extrabold mb-8">
                Submission Confirmed!
            </h1>
            <p className="text-3xl mt-4 text-left mr-64">
                Thank you for your submission. 
            </p>
            <p className="text-3xl mt-2 text-left mr-64"> 
                Wait for the Admin to verify and confirm.
            </p>
            <div className="absolute" style={{ top: '0', right: '0' }}> 
                <img 
                    src="/account.png" // Using direct path from public folder
                    alt="Account Icon" 
                    className="account-image" 
                    style={{ width: '50vw', height: 'auto', objectFit: 'cover' }} // Decreased width to 50vw
                />
            </div>
        </div>
    );
};

export default SubmissionConfirmation;
