import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';

const VendorAddProd = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (!vendorToken) {
        toast.error('Vendor token not found in local storage');
        setLoading(false); // Stop loading
        return;
      }

      if (!selectedFile) {
        toast.error('No file selected');
        setLoading(false); // Stop loading
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await axiosClient.post('/products/upload_products', formData, {
        headers: {
          'Authorization': `Bearer ${vendorToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('File uploaded successfully');
      } else {
        toast.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);

      if (error.response) {
        toast.error(`Failed to upload file: ${error.response.data.message || 'Internal Server Error'}`);
      } else if (error.request) {
        toast.error('No response from server');
      } else {
        toast.error('Error setting up request');
      }
    } finally {
      setLoading(false); // Stop loading in all cases
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen p-4">
      <form
        className="bg-white shadow-md rounded-lg p-6 w-full max-w-md border border-gray-200 transition-transform transform hover:scale-105"
        onSubmit={handleUpload}
      >
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Upload Product</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload CSV/Excel File
          </label>
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-2 rounded-lg hover:bg-orange-600 transition duration-150"
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Adding...' : 'Add Product'}
        </button>
      </form>
      <ToastContainer />

      {loading && (
        <div className="absolute inset-0 z-30 flex flex-col items-center justify-center bg-black bg-opacity-50">
          <div className="relative flex items-center justify-center">
            <div
              className="loader"
              style={{
                width: '80px',
                height: '80px',
                border: '8px solid rgba(255, 255, 255, 0.8)', // Mostly white with transparency
                borderTop: '8px solid orange',
                borderRadius: '50%',
                animation: 'spin 2s linear infinite', // Slower spin
              }}
            />
            <img
              src="/loader.png" // Static loader image path
              alt="Loading..."
              style={{
                width: '60px',
                height: '60px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorAddProd;
