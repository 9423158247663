import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoginPopup from './LoginPopup';
import vendorContext from "../context/vendor/vendorContext"

const TokenExpiryChecker = () => {
  const { expiredToken, checkTokenExpiry } = useContext(vendorContext);
  const location = useLocation();

  useEffect(() => {
    
    checkTokenExpiry();
  }, [location.pathname, checkTokenExpiry]); // Dependency array includes pathname

  if (location.pathname === '/') {
    return null; // Don't show the popup on the login page
  }

  if (location.pathname.startsWith('/payment')) {
    return null; // Don't show the popup on the login page
  }


  return expiredToken ? <LoginPopup /> : null; // Show popup if token is expired
};

export default TokenExpiryChecker;
