import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../components/AxiosClient';

const VendorProducts = () => {
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [saltExpiryDate, setSaltExpiryDate] = useState('');
  const [minPrice, setMinPrice] = useState(0.0);
  const [maxPrice, setMaxPrice] = useState(0.0);
  const [minDiscount, setMinDiscount] = useState(0.0);
  const [maxDiscount, setMaxDiscount] = useState(0.0);

  const [showMRPModal, setShowMRPModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [newMRP, setNewMRP] = useState('');
  const [newDiscount, setNewDiscount] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const editMRP = (productId) => {
    setCurrentProduct(productId);
    setShowMRPModal(true);
  };

  const editDiscount = (productId) => {
    setCurrentProduct(productId);
    setShowDiscountModal(true);
  };

  const handleSubmitMRP = useCallback(async () => {
    if (!newMRP || isNaN(newMRP)) {
      alert('Please enter a valid MRP.');
      return;
    }

    setLoading(true); // Start loading
    try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
        const headers = {
          'Authorization': `Bearer ${vendorToken}`,
          'Content-Type': 'multipart/form-data',
        };

        const formData = new FormData();
        formData.append('new_mrp', parseFloat(newMRP));

        await axiosClient.post(`/products/vendor_edit_mrp/${currentProduct}`, formData, { headers });

        setShowMRPModal(false);
        setNewMRP('');
        fetchProducts();
      }
    } catch (error) {
      console.error('Error updating MRP:', error);
    } finally {
      setLoading(false); // End loading
    }
  }, [newMRP, currentProduct]);

  const handleSubmitDiscount = useCallback(async () => {
    if (!newDiscount || isNaN(newDiscount)) {
      alert('Please enter a valid discount.');
      return;
    }

    setLoading(true); // Start loading
    try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
        const headers = {
          'Authorization': `Bearer ${vendorToken}`,
          'Content-Type': 'multipart/form-data',
        };

        const formData = new FormData();
        formData.append('discount', parseFloat(newDiscount));

        await axiosClient.post(`/products/vendor_discount/${currentProduct}`, formData, { headers });

        setShowDiscountModal(false);
        setNewDiscount('');
        fetchProducts();
      }
    } catch (error) {
      console.error('Error updating discount:', error);
    } finally {
      setLoading(false); // End loading
    }
  }, [newDiscount, currentProduct]);

  const fetchProducts = async () => {
    try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
        let formData = null;
        const headers = {
          'Authorization': `Bearer ${vendorToken}`,
        };

        if (name || saltExpiryDate || minPrice || maxPrice || minDiscount || maxDiscount) {
          formData = new FormData();
          if (name) formData.append('name', name);
          if (saltExpiryDate) formData.append('salt_expiry_date', saltExpiryDate);
          if (minPrice) formData.append('min_price', parseFloat(minPrice));
          if (maxPrice) formData.append('max_price', parseFloat(maxPrice));
          if (minDiscount) formData.append('min_discount', parseFloat(minDiscount));
          if (maxDiscount) formData.append('max_discount', parseFloat(maxDiscount));
          headers['Content-Type'] = 'multipart/form-data';
        }

        const response = await axiosClient.post('/products/get_vendor_products', formData, { headers });
        console.log('API Response:', response.data); // Log the response here
        setProducts(response.data || []);
      } else {
        console.error('Vendor token not found in local storage');
      }
    } catch (error) {
      console.error('Error fetching vendor products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [name, saltExpiryDate, minPrice, maxPrice, minDiscount, maxDiscount]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-semibold mb-6 text-center">Vendor Products</h2>

      <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
        <h3 className="text-xl font-semibold mb-4">Sort & Filter Products</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
          <div>
            <label className="block font-medium mb-1">Name</label>
            <input
              type="text"
              value={name}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setName(e.target.value)}
              placeholder="Product Name"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Salt Expiry Date</label>
            <input
              type="date"
              value={saltExpiryDate}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setSaltExpiryDate(e.target.value)}
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Min Price</label>
            <input
              type="number"
              value={minPrice}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setMinPrice(e.target.value)}
              placeholder="Min Price"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Max Price</label>
            <input
              type="number"
              value={maxPrice}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setMaxPrice(e.target.value)}
              placeholder="Max Price"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Min Discount</label>
            <input
              type="number"
              value={minDiscount}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setMinDiscount(e.target.value)}
              placeholder="Min Discount"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Max Discount</label>
            <input
              type="number"
              value={maxDiscount}
              className="w-full border border-gray-300 rounded p-2"
              onChange={(e) => setMaxDiscount(e.target.value)}
              placeholder="Max Discount"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map(product => (
          <div key={product.object_id} className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h4 className="text-lg font-semibold">{product.name}</h4>
                <div className="text-sm text-gray-600 mt-1">ID: {product.object_id}</div>
              </div>
              <div className="text-gray-500">MRP: ₹{product.Mrp}</div> {/* MRP in top-right */}
            </div>
            <div className="text-gray-500 mb-2">Discount: {product.discount}%</div>
            <div className="flex space-x-4">
              <button
                className="bg-[#FB8802] hover:bg-[#FFE649] text-white px-4 py-2 rounded-md focus:outline-none focus:bg-[#FFE649]"
                onClick={() => editMRP(product.object_id)}
              >
                Edit MRP
              </button>
              <button
                className="bg-[#FB8802] hover:bg-[#FFE649] text-white px-4 py-2 rounded-md focus:outline-none focus:bg-[#FFE649]"
                onClick={() => editDiscount(product.object_id)}
              >
                Edit Discount
              </button>
            </div>
          </div>
        ))}
      </div>

{/* MRP Modal */}
{showMRPModal && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm relative" style={{ height: 'auto', maxHeight: '400px' }}> {/* Adjust height to keep the size consistent */}
      <h3 className="text-xl font-semibold mb-4">Edit MRP</h3>
      {loading ? (
        <>
          <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40" />
          <div className="absolute inset-0 z-50 flex items-center justify-center">
            <div className="relative flex items-center justify-center">
              <div className="loader" style={{
                width: '80px',
                height: '80px',
                border: '8px solid rgba(255, 255, 255, 0.8)',
                borderTop: '8px solid orange',
                borderRadius: '50%',
                animation: 'spin 2s linear infinite',
              }} />
              <img 
                src="/loader.png" 
                alt="Loading..." 
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }} 
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <input
            type="number"
            value={newMRP}
            onChange={(e) => setNewMRP(e.target.value)}
            placeholder="Enter new MRP"
            className="border w-full p-2 rounded mb-4"
          />
          <div className="flex justify-end">
            <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowMRPModal(false)}>Cancel</button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSubmitMRP}>Save</button>
          </div>
        </>
      )}
    </div>
  </div>
)}



      {/* Discount Modal */}
      {showDiscountModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm relative">
            <h3 className="text-xl font-semibold mb-4">Edit Discount</h3>
            {loading ? (
              <>
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40" />
                <div className="absolute inset-0 z-50 flex items-center justify-center">
                  <div className="relative flex items-center justify-center">
                    <div className="loader" style={{
                      width: '80px',
                      height: '80px',
                      border: '8px solid rgba(255, 255, 255, 0.8)',
                      borderTop: '8px solid orange',
                      borderRadius: '50%',
                      animation: 'spin 2s linear infinite',
                    }} />
                    <img 
                      src="/loader.png" 
                      alt="Loading..." 
                      style={{
                        width: '60px',
                        height: '60px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }} 
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <input
                  type="number"
                  value={newDiscount}
                  onChange={(e) => setNewDiscount(e.target.value)}
                  placeholder="Enter new Discount"
                  className="border w-full p-2 rounded mb-4"
                />
                <div className="flex justify-end">
                  <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowDiscountModal(false)}>Cancel</button>
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSubmitDiscount}>Save</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorProducts;
