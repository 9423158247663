import React, { useState, useEffect, useContext } from "react";
import axiosClient from '../components/AxiosClient';
import vendorContext from "../context/vendor/vendorContext"


const SmartDeals = () => {
    const [deals, setDeals] = useState([]);
    const [closedeal, setclosedeal]=useState(null)
    const { expiredToken, checkTokenExpiry } = useContext(vendorContext);


    const [extendingDeal, setExtendingDeal] = useState(null); // Track which deal is being extended
    const [selectedDate, setSelectedDate] = useState({}); // Store new dates for each deal

    useEffect(() => {
        // Fetch products data from the API
        const fetchProducts = async () => {
            try {
                const vendorToken = localStorage.getItem('vendorToken');
                if (vendorToken) {
                    const response = await axiosClient.post('/smartdeals/get_deals', null, {
                        headers: {
                            'Authorization': `Bearer ${vendorToken}`
                        }
                    });
                    console.log('Vendor deals fetched successfully');
                    setDeals(response.data.deals || []);
                } else {
                    console.error('Vendor token not found in local storage');
                }
            } catch (error) {
                console.error('Error fetching vendor products:', error);
            }
        };

        fetchProducts();
    }, [closedeal]);

    const extendDeal = async (dealId) => {
        try {
            const vendorToken = localStorage.getItem('vendorToken');
            const newDate = selectedDate[dealId];
            
            if (vendorToken && newDate) {
                const formData = new FormData();
                formData.append("deal_id",dealId)
                formData.append("new_end_date",newDate)
                

                const response = await axiosClient.post(
                    '/smartdeals/extend_date_smart_deal',
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${vendorToken}`
                        }
                    }
                );
                if(response.status===401){
                    checkTokenExpiry()
                }
                console.log('Deal extended successfully:', response.data);
                setDeals((prevDeals) =>
                    prevDeals.map((deal) =>
                        deal.deal_id === dealId
                            ? { ...deal, end_date: newDate }
                            : deal
                    )
                );
                setExtendingDeal(null); // Close the extend section after submission
            } else {
                console.error('Vendor token not found or invalid date selected');
            }
        } catch (error) {
            console.error('Error extending the deal:', error);
        }
    };

    const closeDeal = async (dealId) => {
        console.log(dealId, "--==-=-")
        try {

            const vendorToken = localStorage.getItem('vendorToken');
            if (vendorToken) {
                const formData = new FormData();
                formData.append("deal_id",dealId)
                const response = await axiosClient.post(
                    '/smartdeals/closed_smart_deal',
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${vendorToken}`
                        }
                    }
                );
                if(response.status===401){
                    checkTokenExpiry()
                }
                console.log('Deal closed successfully:', response.data);
                setclosedeal(true)
                setDeals((prevDeals) =>
                    prevDeals.map((deal) =>
                        deal.deal_id === dealId
                            ? { ...deal, status: 'Closed' }
                            : deal
                    )
                );
            } else {
                console.error('Vendor token not found in local storage');
            }
        } catch (error) {
            console.error('Error closing the deal:', error);
        }
    };

    const toggleExtendSection = (dealId) => {
        setExtendingDeal((prev) => (prev === dealId ? null : dealId));
    };

    const handleDateChange = (dealId, date) => {
        setSelectedDate((prevState) => ({ ...prevState, [dealId]: date }));
    };

    return (
        <>
            <div className="text-xl font-bold p-4">Your Deals</div>
            <div className="flex flex-col gap-4 p-4">
                {deals.map((deal) => (
                    <div key={deal.deal_id} className="bg-slate-200 p-4 rounded-md">
                        <div>Product Id: {deal.product_id}</div>
                        <div>Discount: {deal.discount_percentage}%</div>
                        <div>Start Date: {new Date(deal.start_date).toLocaleDateString()}</div>
                        <div>End Date: {new Date(deal.end_date).toLocaleDateString()}</div>
                        <div>Status: {deal.status}</div>
                        <div>Created At: {new Date(deal.created_at).toLocaleDateString()}</div>

                        {/* Extend Deal Button */}
                        <div className="mt-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                                onClick={() => toggleExtendSection(deal.deal_id)}
                            >
                                {extendingDeal === deal.deal_id ? "Cancel" : "Extend Deal"}
                            </button>
                        </div>

                        {/* Extend Deal Section */}
                        {extendingDeal === deal.deal_id && (
                            <div className="mt-4">
                                <input
                                    type="date"
                                    className="border border-gray-400 p-2 rounded-md"
                                    onChange={(e) => handleDateChange(deal.deal_id, e.target.value)}
                                    value={selectedDate[deal.deal_id] || ''}
                                />
                                <button
                                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 ml-2 rounded-md"
                                    onClick={() => extendDeal(deal.deal_id)}
                                    disabled={!selectedDate[deal.deal_id]}
                                >
                                    Submit New Date
                                </button>
                            </div>
                        )}

                        {/* Close Deal Button */}
                        <div className="mt-4">
                            <button
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                                onClick={() => closeDeal(deal.deal_id)}
                            >
                                Close Deal
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default SmartDeals;
