import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosClient from '../components/AxiosClient';

const Payment = () => {
  const [link, setLink] = useState('');
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  useEffect(() => {
    const initiatePayment = async () => {
      if (isProcessing) return; // Prevent further calls if already processing
      setIsProcessing(true); // Set processing state

      try {
        const paymentLink = await paymentlink();
        console.log(paymentLink, "paymentLink");
      } catch (error) {
        console.error('Payment initiation failed:', error);
      } finally {
        setIsProcessing(false); // Reset processing state
      }
    };

    initiatePayment();
  }, [id]); // Run effect when `id` changes or isProcessing changes

  const paymentlink = async () => {
    try {
      console.log(id); // Log the ID for debugging
      const response = await axiosClient.post(`/subscriptions/vendor_initiate_payment/${id}`, {}, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const paymentUrl = response.data.response.url;
      setLink(paymentUrl); // Set the link state
      return paymentUrl; // Return the payment URL
    } catch (error) {
      console.error('Error initiating payment:', error.response ? error.response.data : error.message);
      throw error; // Optionally re-throw the error for further handling
    }
  };

  return (
    <div className="relative flex justify-center items-center h-screen bg-cover"
    style={{
      backgroundImage: "url('/back.jpg')",
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  >
    {/* Dark overlay */}
    <div className="absolute inset-0 bg-black opacity-70 z-0"></div>

    {/* Logo in Background - Top Left without Blend Mode */}
    <div className="absolute top-5 left-1 z-20 flex items-center">
      <img 
        src="/logo.png" // Ensure this path is correct
        alt="Company Logo" 
        className="w-48 h-24 mr-3" // Adjust size as needed
      />
    </div>

    <div className="bg-white p-8 rounded-lg shadow-md z-20">
      <h2 className="text-2xl font-semibold mb-4" style={{ color: '#FB8802' }}>Vendor Payment</h2>
      <a 
      className="bg-gradient-to-r from-[#FB8802] to-[#FFE649] text-white px-4 py-2 rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        href={link} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Pay Now     
        </a>

    </div>
  </div>
  //   <div className="fixed inset-0 flex items-center justify-center z-50">
  //   <div className="absolute inset-0 bg-gray-800 bg-opacity-75"></div>
  //   <div className="bg-white p-8 rounded shadow-md text-center z-10">
  //     <h2 className="text-xl font-semibold mb-4">Vendor Payment</h2>
  //     {/* <p className="mb-4">Your session has expired. Please log in again.</p> */}
  //    
  //   </div>
  // </div>
);
};

export default Payment;
