import React,{useState} from "react";
import vendorContext from "./vendorContext";
import axiosClient from "../../components/AxiosClient";

const VendorState = (props) => {
  const [expiredToken, setExpiredToken] = useState(false);
  const [verifiedVendor,setVerifiedVendor]=useState(false);
  const checkTokenExpiry = async () => {
    const vendorToken = localStorage.getItem('vendorToken');  // Check vendorToken here
    if (!vendorToken) {
      setExpiredToken(true);
      return;
    }

    try {
      const response = await axiosClient.post('/auth/token_expiry_check', null, {
        headers: {
          Authorization: `Bearer ${vendorToken}`,  // Use vendorToken
        },
      });

      if (response.data.code !== 200) {
        setExpiredToken(true);
        
      } else {
        setExpiredToken(false);
        setVerifiedVendor(true)
      }
    } catch (error) {
      setExpiredToken(true);
    }
  };

  return (
    <vendorContext.Provider value={{ expiredToken, setExpiredToken, checkTokenExpiry,verifiedVendor,setVerifiedVendor }}>
      {props.children}
    </vendorContext.Provider>
  );
};

export default VendorState;
