import React from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingBag, FaClipboardList } from 'react-icons/fa'; // Import icons for vendor options
import { RiLogoutCircleLine } from 'react-icons/ri';

const VendorLeftSection = ({ handleOptionClick }) => {
  const handleLogout = () => {
    // Clear the vendor token from localStorage
    localStorage.removeItem('vendorToken');
    // Redirect to the home page or trigger a logout event
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen w-64 p-4 bg-gradient-to-r from-[#FB8802] to-[#FFE649] z-50 overflow-y-auto"
    >
      {/* Add spacing only on mobile devices */}
      <div className="lg:mt-0 mt-16"> {/* `lg:mt-0` for larger screens, `mt-16` for mobile */}
        <h2 className="text-lg font-bold mb-4 text-black">Options</h2>
        <ul className="space-y-2 text-[18px]">
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/coupons"
              className="flex items-center"
              onClick={() => handleOptionClick('coupons')}
            >
              <FaShoppingBag /> &nbsp; Coupons
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/AddProducts"
              className="flex items-center"
              onClick={() => handleOptionClick('couponexpiry')}
            >
              <FaClipboardList /> &nbsp; Add Product
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/products"
              className="flex items-center"
              onClick={() => handleOptionClick('products')}
            >
              <FaClipboardList /> &nbsp; Your products
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/uploadproduct"
              className="flex items-center"
              onClick={() => handleOptionClick('addproduct')}
            >
              <FaClipboardList /> &nbsp; Upload Product
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/createsmartdeals"
              className="flex items-center"
              onClick={() => handleOptionClick('createsmartdeals')}
            >
              <FaClipboardList /> &nbsp; Create Deal
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/vendordashboard/smartdeals"
              className="flex items-center"
              onClick={() => handleOptionClick('smartdeals')}
            >
              <FaClipboardList /> &nbsp; Smart Deals
            </Link>
          </li>
          <li className="duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
            <Link
              to="/"
              className="flex items-center"
              onClick={handleLogout}
            >
              <RiLogoutCircleLine /> &nbsp; Log Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VendorLeftSection;
