import React, { useState, useContext } from 'react';
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom';
import vendorContext from '../context/vendor/vendorContext';

const VendorLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');  
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();
  const { setExpiredToken, verifiedVendor, setVerifiedVendor } = useContext(vendorContext);

  const handleSendOtp = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Validate that the phone number is a 10-digit number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert('Please enter a valid 10-digit phone number.');
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('phone_number', phoneNumber);
      formData.append('role', 'vendor');

      const response = await axiosClient.post('/auth/send_otp', formData);
      if (response.status === 200) {
        console.log('OTP sent:', response.data);
        setIsOtpSent(true);
        alert("OTP sent successfully.");
      } else {
        console.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    setIsLoading(false);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!otp || !phoneNumber) {
        console.error('Please fill in both OTP and phone number.');
        setIsLoading(false);
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('mobile', phoneNumber);
      formDataToSend.append('otp', otp);

      const response = await axiosClient.post('/auth/vendor_login', formDataToSend);
      console.log('Vendor Login successful:', response.data);

      // Store token in localStorage
      localStorage.setItem('vendorToken', response.data.data[0].access_token);
      const role = response.data.data[0].user.role;
      console.log(role, "role");
      var allow = false;
      if (role === 'vendor') {
        console.log("hello");
        setVerifiedVendor(true);
        localStorage.setItem('VerifiedVendor', true)
        allow = true;
      }

      console.log("Login successful, token stored and expiredToken set to false");

      // Add a 1-second delay before navigating to the dashboard
      setTimeout(() => {
        if (allow)
          navigate('/vendordashboard');
        else
          navigate("/approve");
      }, 1000); // 1 second = 1000 milliseconds
    } catch (error) {
      console.error('Error logging in:', error);
    }
    setIsLoading(false);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Limit input to 10 digits and only numeric values
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhoneNumber(value);
    } else if (value.length > 10) {
      alert('Phone number cannot exceed 10 digits.');
    }
  };

  return (
    <div className="relative flex justify-center items-center h-screen bg-cover"
      style={{
        backgroundImage: "url('/back.jpg')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-70 z-0"></div>

      {/* Logo in Background - Top Left without Blend Mode */}
      <div className="absolute top-5 left-1 z-20 flex items-center">
        <img 
          src="/logo.png" // Ensure this path is correct
          alt="Company Logo" 
          className="w-48 h-24 mr-3" // Adjust size as needed
        />
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md z-20">
        <h2 className="text-2xl font-semibold mb-4" style={{ color: '#FB8802' }}>Vendor Login</h2>
        {!isOtpSent ? (
          <form onSubmit={handleSendOtp}>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block mb-2" style={{ color: '#FB8802' }}>Phone Number:</label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange} // Updated to use the new function
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter your phone number"
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-[#FB8802] to-[#FFE649] text-white px-4 py-2 rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
              disabled={isLoading}
            >
              {isLoading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="otp" className="block mb-2" style={{ color: '#FB8802' }}>Enter OTP:</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter OTP"
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-[#FB8802] to-[#FFE649] text-white px-4 py-2 rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default VendorLogin;
