import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import VendorLogin from './Pages/VendorLogin';
import VendorDashboard from './Pages/VendorDashboard';
import Payment from './Pages/Payment';
import VendorCoupons from './Pages/VendorCoupons';
import VendorCouponExpiry from './Pages/VendorCouponExpiry';
import VendorProducts from './Pages/VendorProducts';
import VendorAddProd from './Pages/VendorAddProd';
import Createsmartdeals from './Pages/VendorCreateSmartDeals';
import SmartDeals from './Pages/VendorSmartDeals';
import TokenExpiryChecker from "./components/TokenExpiry";
import ScrollToTop from './components/ScrollToTop';
import vendorContext from './context/vendor/vendorContext';
import Approve from './Pages/Approve';
import SubmissionConfirmation from './Pages/SubmissionConfirmation'; // Ensure the path is correct
import AddProducts from './Pages/AddProducts';  // Correct import path

function App() {
  return (
    <Router>
      <TokenExpiryChecker />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<VendorLogin />} />
        <Route exact path="/approve" element={<Approve />} />
        <Route exact path="/submission-confirmation" element={<SubmissionConfirmation />} /> {/* Add this line */}
        <Route path="/vendordashboard/*" element={<VendorProtectedRoutes />} />
        <Route exact path="/vendordashboard" element={<VendorDashboard /> } />
        <Route exact path="/payment/:id" element={<Payment />} />
      </Routes>
    </Router>
  );
}

function VendorProtectedRoutes() {
  const { verifiedVendor } = useContext(vendorContext);
  const verifiedVendor1 = localStorage.getItem('VerifiedVendor');

  if (!verifiedVendor1) {
    return <Navigate to="/approve" replace />;
  }

  // VendorDashboard wrapped inside a Route
  return (
    <VendorDashboard>
      {/* <Routes> */}
        <Route exact path="coupons" element={<VendorCoupons />} />
        <Route exact path="couponexpiry" element={<VendorCouponExpiry />} />
        <Route exact path="products" element={<VendorProducts />} />
        <Route exact path="uploadproduct" element={<VendorAddProd />} />
        <Route exact path="createsmartdeals" element={<Createsmartdeals />} />
        <Route exact path="smartdeals" element={<SmartDeals />} />
        <Route exact path="addproducts" element={<AddProducts />} />  // For AddProducts.jsx
      {/* </Routes> */}
    </VendorDashboard>
  );
}

export default App;
