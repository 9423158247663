import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ProductFormFields from '../components/ProductFormFields'; // Import the new component
import FileInput from '../components/FileInput'; // Import the new component
import axiosClient from '../components/AxiosClient';

const AddProducts = () => {
  const [formData, setFormData] = useState({
    name: '',
    name1: '',
    name2: '',
    name3: '',
    name4: '',
    name5: '',
    description: '',
    introduction: '',
    primary_use: '',
    medicine_type: '',
    mrp: '',
    stock: '',
    image: null,
    manufacturers: '',
    manufacturer_address: '',
    packaging: '',
    packaging_type: '',
    label: '',
    salt_composition: '',
    salt_synonyms: '',
    alcohol_interaction: '',
    driving_interaction: '',
    kidney_interaction: '',
    liver_interaction: '',
    lactation_interaction: '',
    pregnancy_interaction: '',
    other_drugs_interaction: '',
    safety_advice: '',
    how_to_use: '',
    if_miss: '',
    storage: '',
    benefits: [],
    side_effects: [],
    facts: [],
    q_a: [],
    country_of_origin: '',
    prescription_required: '',
    use_of: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const adminToken = localStorage.getItem("adminToken");
      if (!adminToken) {
        toast.error("Admin token not found in localStorage.");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          if (Array.isArray(formData[key])) {
            formData[key].forEach((item) => formDataToSend.append(key, item));
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
      }

      const mrpValue = parseFloat(formData.mrp);
      if (!isNaN(mrpValue)) {
        formDataToSend.append('mrp', mrpValue);
      } else {
        toast.error('MRP is not a valid number.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${adminToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post('/products/create_product', formDataToSend, config);
      toast.success('Product added successfully!');
      
      // Reset form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error('Error adding product: ' + (error.response.data.message || 'Internal Server Error'));
      } else {
        toast.error('Error adding product: ' + error.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  // Reset function to clear the form
  const resetForm = () => {
    setFormData({
      name: '',
      name1: '',
      name2: '',
      name3: '',
      name4: '',
      name5: '',
      description: '',
      introduction: '',
      primary_use: '',
      medicine_type: '',
      mrp: '',
      stock: '',
      image: null,
      manufacturers: '',
      manufacturer_address: '',
      packaging: '',
      packaging_type: '',
      label: '',
      salt_composition: '',
      salt_synonyms: '',
      alcohol_interaction: '',
      driving_interaction: '',
      kidney_interaction: '',
      liver_interaction: '',
      lactation_interaction: '',
      pregnancy_interaction: '',
      other_drugs_interaction: '',
      safety_advice: '',
      how_to_use: '',
      if_miss: '',
      storage: '',
      benefits: [],
      side_effects: [],
      facts: [],
      q_a: [],
      country_of_origin: '',
      prescription_required: '',
      use_of: '',
    });
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8">
      <div className='flex'>
        <h1 className="text-xl md:text-2xl font-bold my-4 text-center w-full">Add Product</h1>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-4 md:p-6 rounded-md shadow-md">
        <ProductFormFields formData={formData} handleChange={handleChange} />
        <FileInput handleFileChange={handleFileChange} />
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
          <button type="button" onClick={resetForm} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProducts;
